import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/model-performances',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/model-performances',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data.items[0])
  } catch (e) {
    return e
  }
}

export async function attribute(ctx, queryParams = {}) {
  try {
    let attr = queryParams.attribute
    delete queryParams.attribute
    const response = await axiosInstance({
      url: '/model-performances/' + attr,
      params: queryParams,
      method: 'GET'
    })
    ctx.commit(attr, response.data.items)
  } catch (e) {
    return e
  }
}
