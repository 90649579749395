export function items(state) {
  return state.items
}

export function item(state) {
  return state.item
}

export function pagination(state) {
  return state.pagination
}

export function models(state) {
  return state.models
}

export function transmissions(state) {
  return state.transmissions
}

export function years(state) {
  return state.years
}

export function motors(state) {
  return state.motors
}
