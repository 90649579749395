import Vue from 'vue'
import Vuex from 'vuex'

import carmodels from './car-models'
import versions from './versions'
import colors from './colors'
import characteristics from './characteristics'
import specifications from './specifications'
import comments from './comments'
import dealerships from './dealerships'
import promotions from './promotions'
import modules from './modules'
import customcar from './custom-car'
import cars from './cars'
import appconfigurations from './app-configurations'
import departments from './departments'
import metatags from './meta-tags'
import quote from './quote'
import accessories from './accessories'
import app from './app'
import wantedCars from './wanted-cars'
import contact from './contact'
import promotionClients from './promotion-clients'
import boostersale from './boostersale'
import forms from './forms'
import prospect from './prospect'
import modelPerformances from './model-performances'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function(/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      carmodels,
      versions,
      colors,
      characteristics,
      specifications,
      comments,
      dealerships,
      promotions,
      modules,
      customcar,
      cars,
      appconfigurations,
      departments,
      metatags,
      quote,
      accessories,
      app,
      'wanted-cars': wantedCars,
      contact,
      'promotion-clients': promotionClients,
      'booster-sale': boostersale,
      forms,
      prospect,
      'model-performances': modelPerformances
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  })

  return Store
}
