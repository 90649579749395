export function items(state, data) {
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function item(state, data) {
  state.item = data
}

export function models(state, data) {
  state.models = data
}

export function years(state, data) {
  state.years = data
}

export function transmissions(state, data) {
  state.transmissions = data
}

export function motors(state, data) {
  state.motors = data
}
